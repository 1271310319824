import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';

const title="Membership Through NFT's"
const image="digital_apes.png"
const txt="nfts for members"
const caption="NFT communities have seen great success."

const hack_text = <Fragment>

<p>Developments happen so fast in crypto it can be easy to miss them.</p>
<p>      For example, most people know the name of NFT, now. But do they know what they enabled?</p>
<p>      NFT's like the Bored Ape Yacht Club have raised so much money, so fast, that they've essentially enabled a new kind of funding model.</p>
<p>      In the case of the Bored Ape Yacht Club, their haul, so far, is 26.2 million dollars.</p>
<p>      But rather than focus on the amount, think about what that signifies. It means that many crypto holders are willing to pay to be part of a community. If you can make that community for them, or make a community they want to join, they'll support you. Hugely.</p>
<p>      Sometimes, it's groups, or companies, doing this. In the case of the Bored Apes, its Yuga Labs.</p>
<p>      In some cases, though, it's individuals. The most famous one today is probably Gary Vee.</p>
<p>      He's an influencer, and a marketing genius, who's all but unavoidable, on YouTube. He's now created his own NFT collection, to gatekeep access to his community.</p>
<p>      And his people love it. A quick check of his website, today, shows his typical NFT's selling from 51,000 to just over 200,000.</p>
<p>      We're impressed - and we'd like to see those outcomes for smaller creators, too.</p>
<h2>      The Plan      </h2>
	<img alt="gary vee nft website" src="vee_banner.png"/>
<p>      We're reorienting around a new NFT concept: using NFT's to gate access to resources.</p>
<p>      It's pretty simple.</p>
<p>      First, you buy the NFT's. That can happen anywhere: off OpenSea, from someone you know, through a wallet send.</p>
<p>      Then, you use those NFT's to access resources, or places, that have value to you.</p>
<p>      It could be your favorite influencer's online website, or a behind the scenes venue, virtual or physical. </p>
<p>      Ownership of the NFT is proof that you belong. And like all such blockchain things, it's cryptographically secure. When you're in, you're in forever.</p>
<p>      Not only do you get to support your favorite creator, but you can also join a community of other people who do too, who share your interests. By buying your NFT, you signify that you belong.</p>
<p>      We're working on the first phase of this now, creating the smart contract NFT's. So far, this has all gone according to plan.</p>
<p>      Next, we create the communities, things you can only access through your NFT's. We're starting on that part, essentially the front end part, next week.</p>
<p>      We're excited by the opportunities that NFT's bring to creators, and we're looking to be a part of the movement that empowers them, through NFT community-building tools.</p>
<p>      </p>
<p>      Follow us if you're a creator, or a fan of one - because we want to make your lives easier, and through that, make the world a better place.</p>

      
      
</Fragment>

const body = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
	{title}
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg pt-6 pb-2"
              src={image}
              alt={txt}
            />
            <figcaption>{caption}</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
	{hack_text}
        </div>
      </div>
</Fragment>

const NFTMembership = () => {
	return (
		<BlogForm content={body} />
	)
}

export default NFTMembership;
