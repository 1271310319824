import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';

const title="Making Prototypes"
const image="hack_make.png"
const txt="people at hackathon illustration"
const caption="A hackathon is a community effort."

const hack_text = <Fragment>
<p>Crypto has changed the way we think about all kinds of things.</p>
<p>For example, financial education. There was a time when "liquidity pool" would've been considered too complicated, something an average person shouldn't care about. </p>
<p>That's changed, thanks to DeFi. It's entered the culture. Average people know what they are, thanks to explanations in news articles and media.</p>
<p>Another one of those changes came through hackathons.</p>
<p>It used to be that hackathons were PR exercises for big companies. Nothing serious came from them. The real action happened in the headquarters, and hackathons were for no competition for them.</p>
<p>This changed, too.</p>
<p>Crypto was really the first to introduce the value of decentralization to the popular consciousness - and deliver on it too.</p>
<p>Today hackathons serve as decentralized idea incubators, essentially, within crypto. </p>
<p>We support that, and we're here to support their ecosystems too, with our work.</p>
<h2>The Work</h2>
<p>We're fascinated by NFT's, and the level of adoption they've experienced.</p>
<p>NFT's are making new things possible within crypto. If you want one because you love how it looks, or what it does, because it might rise in value, of because you want to be a part of the group it signifies - NFT's can do this.</p>
<p>We're working on an NFT project this week, on launching one and building a web app for it.</p>
<p>Once we have that under our belts, we'll grow from there.</p>
<p>Follow us here for more updates, and thanks, as always, for reading.</p>
</Fragment>

const body = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
	{title}
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg"
              src={image}
              alt={txt}
            />
            <figcaption>{caption}</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
	{hack_text}
        </div>
      </div>
</Fragment>

const Prototypes = () => {
	return (
		<BlogForm content={body} />
	)
}

export default Prototypes;
