/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const people = [
  {
    name: 'Julian Martinez',
    role: 'Principal',
    imageUrl:
      'cc_julian.png',
    bio:
      "Julian Martinez started working with crypto at Ripple in 2014. He is CurrentCoin's spokesperson.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Timothy Dunleavy',
    role: 'Strategist',
    imageUrl:
      'cc_tim.png',
    bio:
      "Timothy Dunleavy contributed strategic advice. He heads the financial function of a cryptocurrency protocol.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Dan Napierski',
    role: 'Consultant',
    imageUrl:
      'cc_dan.png',
    bio:
      "Dan Napierski analyzed our project and provided recommendations. He received his MS in Computer Science from Harvard.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Charles Cooper',
    role: 'Consultant',
    imageUrl:
      'cc_charles.png',
    bio:
      "Charles Cooper contributed to our roadmap. He develops high frequency trading applications.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Gilles Pirio',
    role: 'Consultant',
    imageUrl:
      'cc_gilles.png',
    bio:
      "Gilles Pirio helped with project management. He has over 2 decades of Silicon Valley experience.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Jason Emberley',
    role: 'Developer',
    imageUrl:
      'cc_jason.png',
    bio:
      "Jason Emberley prototyped CurrentCoin services. He is a Bay Area engineer.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Greg Audel',
    role: 'Public Relations',
    imageUrl:
      'cc_greg.png',
    bio:
      "Greg Audel helped define our story. He has worked in public relations for over two decades.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'John Thai',
    role: 'Consultant',
    imageUrl:
      'cc_john.png',
    bio:
      "John Thai suggested market opportunities. He is a senior software engineer in the oil industry.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Amanda Bullington',
    role: 'Consultant',
    imageUrl:
      'cc_amanda.png',
    bio:
      "Amanda Bullington has worked in communications at Ripple and in San Francisco fintech startups. She contributed marketing material.", 
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Evgenii Kozinchenko',
    role: 'Public Relations',
    imageUrl:
      'cc_evgenii.png',
    bio:
      "Evgenii Kozinchenko is the owner of a brand consultancy with clients such as Latoken. He gave brand advice.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Goolzzi Jean',
    role: 'Developer',
    imageUrl:
      'cc_goolzzi.png',
    bio:
      "Goolzzi Jean is a JavaScript developer. He contributed code to multiple token projects, including CurrentCoin.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Ramin Taghizada',
    role: 'Consultant',
    imageUrl:
      'cc_ramin.png',
    bio:
      "Ramin Taghizada is an enterprise fintech and open source developer. He consulted for CurrentCoin.",
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  // More people...
]


export default function Contributors() {
  return (
    <div className="">
      <div className="mx-auto bg-white mt-4 py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Contributors</h2>

          <ul
            role="list"
            className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                  <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                    <img className="object-cover shadow-sm rounded-sm" src={person.imageUrl} alt="" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-indigo-600">{person.role}</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-700">{person.bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
