import React, { Component, useState } from "react";
import './bloggy.css';
import './css/bootstrap.css';
import blogs from './datablog';
import styled from 'styled-components';
import Poster from './Poster';

const spacer= {
    marginTop: '3vh',
    marginBottom: '3vh',
    backgroundColor: 'white',
    color: 'black',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingBottom: '1vh',
    paddingTop: '1vh',

}

const boxrip={
    padding: '1%',
    margin: '0 auto',
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.1)',
    paddingBottom: '2vh',
    paddingTop: '2vh',
}


const Post = ({ match, location }) => {
    const {
        params: { topic }
    } = match;

    const result=blogs.filter((entry) => entry.text === topic);

    return (

        <div style={{backgroundColor: '#838486',}}>
          <Poster title={result[0].name} body={result[0].body} banner={result[0].banner}/>
        </div>
    );
};

export default Post;
