import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Extremely Reliable',
    description: "CurrentCoin, as an ERC-20 token, is as sturdy and dependable as Ethereum is.",
  },
  { name: 'Solid Engineering', description: 'We are committed to delivering value through the use of time-tested technologies.',
  },
  {
    name: 'Inclusive Approach',
    description: 'Our websites are designed to appeal to users across all demographics.',
  },
  { name: 'Experienced People', description: 'Our contributors are seasoned technology veterans.' },
  { name: 'Potential Reach', description: 'As cryptocurrencies become more well known, the size of the market will increase.',
    },
  { name: 'Prototype First', description: "We plan to prototype new services and release them quickly.",
  },
  { name: 'Regular Updates', description: 'Through our blog, you can monitor our progress.' },
  { name: 'Community Orientation', description: "We're here to find success in the market by serving our community." },
]

export default function Checkmarks() {
  return (
    <div className="bg-white" >
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">For The People</p>
          <p className="mt-4 text-lg text-gray-500">
            We want our project to reach a big audience. Our choices reflect this.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
