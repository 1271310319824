import './App.css';
import './index.css';
import BlogForm from './BlogForm';
import { Fragment } from 'react';

const hack = <Fragment>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="p-2 mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              A Changing World
            </span>
          </h1>
          <figure>
            <img
              className="w-full rounded-lg"
              src="vitalik.png"
              alt=""
            />
            <figcaption>Vitalik discussing world changes on Twitter.</figcaption>
          </figure>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
<p>	  Many things have changed since our project entered the world in 2018. However, some things have stayed the same.</p>

<p>People are interested in value, users want to support good projects, and great opportunity is out there.</p>

<p>CurrentCoin is now working on two projects to determine our future direction.</p>

<p>We'll start where all projects for builders do - with a prototype.</p>

<p>For this, there are two hackathons we are targeting in late 2021: the <strong>Ethereum Online</strong> hackathon and the <strong>Solana Ignite</strong> hackathon.</p>
	  <p> We believe in a multi-blockchain world - and we're targeting it with our work.</p>

          <h2>The Hackathons</h2>
<p>The dates of these hackathons will provide the beginning and end dates of our 'sprint' - natural guidelines.</p>

<p>To maximize our chances, we're targeting two areas: NFT's, and DeFi.</p>

<p>NFT's have become very hot. But on ethereum and Solidity-compatible blockchains, the prices can be very high.</p>

<p>This is a good use case for Solana, because minting NFT's on Solana costs pennies, while it costs minimum 20 dollars on Ethereum.</p>

<p>That's why we're targeting the Solana blockchain for an NFT project. Users holding CurrentCoin will be able to bridge to it, using our code.</p>

<p>Users will ultimately be able to join our prototype platform and issue generative NFT's through it, using a Solana token that bridges to CurrentCoin as an ERC20 token.</p>

<p>For Solidity, we've seen DeFi take the world by storm. There's a lot of liquidity locked up in Ethereum and we want to tap into that market.</p>

<p>We'll be creating a DeFi product that allows you to farm liquidity pools with others and find good opportunities, up to and including flash loan strategies.</p>

<p>Think of what makes DeFi great - 'money legos' - applied to loans and arbitrage.</p>

<p>This offering will be tailored to AAVE, through services that can be purchased with CUN.</p>

          <h2>Timeline</h2>
<p>The Solana hackathon is 5 weeks long. It started on 8/31/21 and ends on 10/8/21.</p>

<p>The Ethereum hackathon is 1 month long. It starts on 9/17/21 and ends on 10/15/21.</p>

<p>For each hackathon, we plan to deploy a minimal prototype which will hopefully enough attention to 'soft launch' us and build up a userbase.</p>

<p>Each hackathon has a number of live teaching sessions w experts we can attend over Zoom. We'll be following these over the next few weeks to learn the ecosystem and form relationships with developers.</p>

<p>By mid-October, we should have 2 prototypes to display, and a solid idea of what to build out next.</p>

<p>We'll also be communicating through here, our new blog site, on a regular basis. Check back on Fridays for new updates.</p>
        </div>
      </div>
</Fragment>

const Hackathons = () => {
	return (
		<BlogForm content={hack} />
	)
}

export default Hackathons;
