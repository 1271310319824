import React, { Component, useState } from "react";
import './bloggy.css';
import './css/bootstrap.css';
import styled from 'styled-components';
import blogs from './datablog';
import announces from './announceblog';
import { Link } from 'react-router-dom';

const spacer= {
    marginTop: '3vh',
    marginBottom: '3vh',
    backgroundColor: 'white',
    color: 'black',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingBottom: '1vh',
    paddingTop: '1vh',

}

const boxrip={
    padding: '1%',
    margin: '0 auto',
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.1)',
}

const CenterSkew=styled.div`
text-align: center;
margin-top: 10vh;
padding-top: 4vh;
padding-bottom: 4vh;
background-color: black;
background-color: #b3cdd1;
background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
transform: skewY(-4deg);
`

const UnSkew=styled.div`
transform: skewY(4deg);
padding-top: 8vh;
padding-bottom: 8vh;
`

const Padder=styled.div`
background-color: white;  
width: 360px;
margin: auto;
padding-top: 2vh;
padding-bottom: 1vh;
`

class Posts extends Component {
    render() {
        return (
            <div>	              
            <CenterSkew>
              <UnSkew>
                <Padder>
		<Padder>
                  <h1 style={{color: 'blue',}}>Notices</h1>
                  {announces.map((announce, index) => (
                      <h5 key={index}>
                        <Link to={`/post/${announces[index].text}`}><p>{announce.name} <span style={{color: 'gray',}}> {announce.date}</span></p></Link>
                      </h5>
                  ))}
		</Padder>
		<Padder>
                  <h1 style={{color: 'blue',}}>Blog</h1>
                  {blogs.map((blog, index) => (
                      <h5 key={index}>
                        <Link to={`/post/${blogs[index].text}`}><p>{blog.name} <span style={{color: 'gray',}}> {blog.date}</span></p></Link>
                      </h5>
                  ))}
                </Padder>
                </Padder>
            </UnSkew>                
            </CenterSkew>

            </div>
        );
    }
}

export default Posts;
