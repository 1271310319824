import Features from './Features';
import Checkmarks from './Checkmarks';
import Teams from './Teams';
import Contributors from './Contributors';
import Top from './Top';
import Liner from './Liner';
import Pricing from './Pricing';
import Faq from './Faq';
import ReadBlog from './ReadBlog';
import Footer from './Footer';
import Cube from './Cube';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'

const Bul=()=>{
	return (
		<div style={{backgroundImage: "url('bulge.svg')",height: '40vh',}}>
		</div>
	)
}

const Splash = () => {
	return (
		<div>
		<Top/>
		<Features/>
		<Checkmarks/>
		<Pricing/>
		<Contributors/>
		<ReadBlog/>
		<Faq/>
		<Footer/>
		</div>
  )
}

export default Splash;
