import React, { Fragment, useState }  from 'react';
import hpic from './blimage/hyperpic.png';
import dman from './blimage/devman.png';
import mainnet from './blimage/main_net.png';
import chartmarket from './blimage/chart_market.png';
import hoodman from './blimage/hoodman.png';
import bitmartpic from './blimage/bitmartpic.png';
import robot from './blimage/roboto.png';
import sman from './blimage/serverman.png';
import btt from './blimage/bittorrent_today.png';
import lambda from './blimage/lambda.png';
import lb from './blimage/lamba.png';
import chr from './blimage/chrissy.png';
import hacks from './blimage/hacks.png';
import hackex from './blimage/bitpoint.png';
import lamba from './blimage/lamba.png';
import ctweet from './blimage/crypto_means.png';
import builder from './blimage/builder.png';
import shanghai from './blimage/shanghai.png';
import china from './blimage/china_crypto.png';
import marshall from './blimage/marshall.png';
import up from './blimage/upblue.png';
import onebtc from './blimage/one_btc.png';
import issuance from './blimage/issuance.png';
import cmetrics from './blimage/coinmetrics.png';
import goldy from './blimage/golddudes.png';
import wallstreet from './blimage/wallstreet.png';
import pants from './blimage/pants.png';
import shovels from './blimage/shovels.svg';
import winklevoss from './blimage/winklevoss.png';
import binance from './blimage/binance_us.png';
import warehouse from './blimage/warehouse.png';
import decentral from './blimage/decentralized.png';
import trends from './blimage/trends.png';
import gartner from './blimage/gartner.png';
import feechart from './blimage/gas_used.png';
import avgfee from './blimage/avg_fee.png';
import ethtweet from './blimage/tweet_fee.png';
import libra_state from './blimage/libra_state.png';
import kirchner from './blimage/kirchner.png';
import zstage from './blimage/mass_talks.png';
import zcx from './blimage/mcx.png';
import zdemo from './blimage/mdemo.png';
import blockgraphic from './blimage/blockgraphic.png';
import justin from './blimage/justin.png';
import chao from './blimage/cz_binance.png';
import business from './blimage/business.png';
import logins from './blimage/login.png';
import peeps from './blimage/peeps.png';
import newz from './blimage/newz.png';
import huobi_chris from './blimage/huobi_chris.png';
import vitaman from './blimage/vitaman.png';
import larsen from './blimage/larsen.png';
import mccaleb from './blimage/mccaleb.png';
import dot from './blimage/dot.png';
import arrowglobe from './blimage/arrow_globe.png';

const backblack={
backgroundColor: 'black',
color: 'white',
}

const Boxstop = () => {
   return (
        <span className='box-stop'></span>        
    ) } 
const Boxgo = () => {
   return (
       <span className='box-go'></span>        
    ) }
const Boxslow = () => {
    return (
        <span className='box-slow'></span>        
    ) }

const headline={
    fontFamily: 'Noto Sans JP, sans-serif',
    fontSize: '2vh',
    color: 'black',
}

const Flipper = (props) => {
    const [toggler, setToggler]= useState(true);

    function SwitchToggle() {
	setToggler(!toggler);
    }
    
    return (
            <div>
	    <p onClick={SwitchToggle}>{toggler ? props.title : props.text }</p>
            </div>
    );
}

const blk={
    color: 'black',
}

export const text_postann=<Fragment><p style={{color: 'black',}}>CurrentCoin is continuing with its plans for 2020, keeping its most important goals first and foremost.</p><p style={{color: 'black',}}>Our most important priority, over and above everything else, is focus.</p><p style={{color: 'black',}}>Originally, we had planned to make releasing our official main net, on Hyperledger, our focus, until the end of the year.</p><p style={{color: 'black',}}>We've decided to shift to a more important priority: being listed on exchanges.</p><p style={{color: 'black',}}>This affects main net, because ERC-20, our main net now, is easier to integrate against than Hyperledger.</p><p style={{color: 'black',}}>It also removes the need to have a token exchange plan, which could delay our exchange listing timetable.</p><p style={{color: 'black',}}>We are continuing development on main net, and you can expect to see a test main net you can interact with shortly.</p><p style={{color: 'black',}}>For the moment, however, we are putting all our energy into being listed on a good exchange by end of year.</p><p style={{color: 'black',}}>As many news sources have noted, crypto is back. We know the spotlight is turning back towards projects like ours, and we are committed to positioning CurrentCoin to best advantage at times like these.</p><p style={{color: 'black',}}>We appreciate your support as we continue to developer CurrentCoin, and work to expand our reach across the world.</p></Fragment>

export const text_ann=<Fragment><p style={{color: 'black',}}>CurrentCoin is planning to open up our main net to the public on September 1, 2020.</p><p style={{color: 'black',}}>Our main net will be deployed to Hyperledger Iroha, and will have its own native token, CurrentCoin.</p><p style={{color: 'black',}}>Upon release, every owner of CurrentCoin will be able to interact with the network.</p><p style={{color: 'black',}}>Our goals were efficiency, quick transactions, and a transparent structure. After much deliberation, we decided on Hyperledger Iroha, an acclaimed and rapidly improving project, as the best vehicle for our goals.</p><p style={{color: 'black',}}>Once deployed, our main net, and its nodes, will close transactions in 3-5 seconds. Nodes will create blocks that will record transactions immutably, in perpetuity.</p><p style={{color: 'black',}}>Through apps, users will be able to interact with this main net, quickly and securely.</p><p style={{color: 'black',}}>Over time, we'll be generating a platform that aims to bring cryptocurrency, and the benefits of blockchain solutions, to all.</p><p style={{color: 'black',}}>Unlike tokens that use gas or a second cryptocurrency, all CurrentCoint transactions will take place exclusively in CUR.</p><p style={{color: 'black',}}>After our main net has been released, we'll work on creating documentation to onboard developers, explain our structure to users, and increase adoption, especially through exchanges.</p><p style={{color: 'black',}}>We'll also be releasing a tool so that owners of CurrentCoin can migrate from Ethereum to this new Hyperledger asset, automatically and with complete security.</p><p style={{color: 'black',}}>More updates will be posted as they become available.</p></Fragment>

export const text_exch=<Fragment><p style={{color: 'black',}}>CurrentCoin is looking to be placed on a major exchange by the end of 2020.</p><p style={{color: 'black',}}>The exchange ecosystem has undergone a revolution since Bitcoin first electrified the world, 11 years ago.</p><p style={{color: 'black',}}>Today, after several generations of crypto exchanges have come and gone, there's an emerging consensus on what it takes to be trusted with valuable, irrecoverable, assets.</p><p style={{color: 'black',}}>It should be safe. It should be compliant. It should take security seriously. And it should have enough staying power to prove its resistant to hacks.</p><p style={{color: 'black',}}>A few exchanges have reached this level. They've become institutions, bywords we trust: Binance, Coinbase.</p><p style={{color: 'black',}}>These are names that are synonymous with security, reliability, and quality; for many users - according to statistics, the majority - they define what it means to be the owner of a cryptocurrency.</p><p style={{color: 'black',}}>As we've begun building out our main net, we've also started to explore exchanges that meet these high standards. We want to make an equal exchange: by bringing the value of the CurrentCoin community to them, we also want them to bring high standards of custodianship, to us.</p><p style={{color: 'black',}}>Because if an exchange doesn't bring value to our users, then we don't want to be a part of it.</p><p style={{color: 'black',}}>We're working off of our list now, and making progress. When we're ready, we plan to announce a partnership by the end of 2020.</p><p style={{color: 'black',}}>When we find the answer we're looking and sign the agreement, our community will be the first to know.</p><p style={{color: 'black',}}>In the meantime, thank you for your support, and for staying with us as we develop the potential of CurrentCoin for the world.</p></Fragment>

export const text_bitmart=<Fragment><p style={{color: 'black',}}>CurrentCoin is being listed on BitMart!</p><p style={{color: 'black',}}>BitMart has proven itself to be a reliable, reputable exchange. We are proud to partner with BitMart to offer CurrentCoin for sale, paired against Ethereum.</p><p style={{color: 'black',}}>You can see their tweet confirming the announcement here.</p><p style={{color: 'black',}}>https://twitter.com/BitMartExchange/status/1307790695088889858</p><p style={{color: 'black',}}>We are also holding an AMA (Ask Me Anything) with their team, to celebrate.</p><p style={{color: 'black',}}>We hope to make this only the first in a series of important announcements, as we move on to bigger & better things in 2020 and beyond.</p><p style={{color: 'black',}}>Thank you for being a member of our community, and keep following us for more announcements!</p></Fragment>

export const text_hiatus=<Fragment><p style={{color: 'black',}}>CurrentCoin is on hiatus.</p><p style={{color: 'black',}}> We'll update here if this changes.</p></Fragment>

const announces = [
  {
      name: `CurrentCoin: On Hiatus`,
      text: `currentcoin-on-hiatus`,
      body: text_hiatus,
      banner: chartmarket,
      date: `| 6/1/23`,
  },
  {
      name: `CurrentCoin: Now on BitMart`,
      text: `currentcoin-now-on-bitmart`,
      body: text_bitmart,
      banner: bitmartpic,
      date: `| 1/1/20`,
  },
  {
      name: `Our Exchange Goals`,
      text: `exchange-view`,
      body: text_exch,
      banner: chartmarket,
      date: `| 10/1/19`,
  }
];

export default announces;
