import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import React from 'react';
import Splash from './Splash';
import Posts from './Posts';
import Post from './Post';
import Poster from './Poster';
import "tailwindcss/tailwind.css";
import Hackathons from './Hackathons';
import Prototypes from './Prototypes';
import NFTMembership from './NFTMembership';

function App() {
  return (
       	<Router>
    	<div>
            <Route path="/" exact component={Splash} />
            <Route path="/posts" component={Posts} />
            <Route path="/post/:topic" component={Post} />
            <Route path="/poster" component={Poster} />
    	</div>
	</Router>
  )
}
 
export default App;


